<template>
  <h1>Accept Privacy Policy</h1>
</template>

<script>
export default {
  name: 'AcceptPrivacyPolicy',
};
</script>

<style scoped></style>
